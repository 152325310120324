import { HeaderCell } from '../../../interfaces';
import Task from '../../../models/task';

const headerCells: HeaderCell<Task>[] = [
  {
    key: 'taskId',
    label: 'ID',
    sortable: true,
    width: '10%',
  },
  {
    key: 'name',
    label: 'Nome',
    sortable: true,
    width: '80%',
  },
  {
    label: 'Desativar',
    sortable: false,
    width: '10%',
  },
];

const columnsAmount = headerCells.length;

const tasksTableService = {
  headerCells,
  columnsAmount,
};

export default tasksTableService;

const EMAIL_BELONGS_TO_A_USER = 'email already belongs to a user';
const CAN_NOT_DEACTIVATE_OWN_USER = 'can not deactivate own user';
const CAN_NOT_REGRESS_OWN_PERMISSION = 'can not regress own permission';
const TAX_NUMBER_BELONGS_TO_A_COMPANY = 'tax number already belongs to a company';
const TASK_NAME_ALREADY_EXISTS = 'task name already exists';

const errorMessages = {
  EMAIL_BELONGS_TO_A_USER,
  CAN_NOT_DEACTIVATE_OWN_USER,
  CAN_NOT_REGRESS_OWN_PERMISSION,
  TAX_NUMBER_BELONGS_TO_A_COMPANY,
  TASK_NAME_ALREADY_EXISTS,
};

export default errorMessages;

import auth from './auth';
import cashFlow from './cash-flow';
import company from './company';
import expense from './expense';
import payment from './payment';
import task from './task';
import trial from './trial';
import user from './user';

const api = {
  auth,
  user,
  company,
  trial,
  payment,
  expense,
  cashFlow,
  task,
};

export default api;

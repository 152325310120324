import React, { useEffect, useState } from 'react';
import Task from '../../models/task';
import tasksService from './service/tasks-service';
import searchHelper from '../../shared/utils/search-helper';
import { TasksTable, TopLayout } from './components';
import CreateTaskAttributes from '../../interfaces/tasks/create-task-attributes';
import CreateTaskModal from './components/create-task-modal/create-task-modal';

export default function Tasks() {
  const [isCreateTaskModalOpen, setIsCreateTaskModalOpen] = useState<boolean>(false);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [isLoadingTasks, setIsLoadingTasks] = useState<boolean>(true);
  const [filteredTasks, setFilteredTasks] = useState<Task[]>([]);
  const [tasksSearchTerm, setTasksSearchTerm] = useState<string>('');

  const fetchTasksData = async () => {
    setIsLoadingTasks(true);
    const fetchedTasks = await tasksService.getTasks().catch(() => []);
    setTasks(fetchedTasks);
    setFilteredTasks(fetchedTasks);
    setIsLoadingTasks(false);
  };

  const handleCreateTask = async (createTaskAttributes: CreateTaskAttributes) => {
    await tasksService.createTask(createTaskAttributes).then((createdTask) => {
      setTasks([...tasks, createdTask]);
      setIsCreateTaskModalOpen(false);
    }).catch(() => {
      setIsCreateTaskModalOpen(true);
    });
  };

  const handleDisableTask = async (taskId: number) => {
    await tasksService.disableTask(taskId).then(() => {
      setTasks(tasks.filter((task) => task.taskId !== taskId));
    });
  };

  useEffect(() => {
    fetchTasksData();
  }, []);

  useEffect(() => {
    const filtered = searchHelper.getFilteredRecordsBySearchTerm<Task>(tasks, tasksService.partialMatchKeys, tasksService.fullMatchKeys, tasksSearchTerm);
    setFilteredTasks(filtered);
  }, [tasks, tasksSearchTerm]);

  return (
    <div>
      <CreateTaskModal
        isOpen={isCreateTaskModalOpen}
        onClose={() => setIsCreateTaskModalOpen(false)}
        createTask={handleCreateTask}
      />

      <div className="mb-5">
        <TopLayout
          setIsCreateTaskModalOpen={setIsCreateTaskModalOpen}
          onSearchTermChange={(searchTerm: string) => setTasksSearchTerm(searchTerm)}
        />
      </div>
      <TasksTable tasks={filteredTasks} isLoadingTasks={isLoadingTasks} onDisableTask={handleDisableTask} />
    </div>
  );
}

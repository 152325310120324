enum SideMenuTitle {
  TRIALS = 'Processos',
  COMPANIES = 'Empresas',
  TASKS = 'Serviços',
  PAYMENTS = 'Pagamentos',
  CASH_FLOWS = 'Fluxo de Caixa',
  USERS = 'Usuários',
}

export default SideMenuTitle;

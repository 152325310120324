import React from 'react';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import ImportExportOutlinedIcon from '@mui/icons-material/ImportExportOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import {
  PageTitle, Path, Role, SideMenuTitle,
} from '../shared/enums';
import Companies from './companies/companies';
import CashFlows from './cash-flows/cash-flows';
import Login from './login/login';
import Trials from './trials/trials';
import Users from './users/users';
import Payments from './payments/payments';
import { Page } from '../interfaces';
import Tasks from './tasks/tasks';

const pages: Page[] = [
  {
    path: Path.LOGIN,
    component: <Login />,
    withoutLayout: true,
  },
  {
    path: Path.TRIALS,
    component: <Trials />,
    sideMenuOption: {
      title: SideMenuTitle.TRIALS,
      icon: <AssignmentOutlinedIcon />,
    },
  },
  {
    path: Path.COMPANIES,
    component: <Companies />,
    title: PageTitle.COMPANIES,
    sideMenuOption: {
      title: SideMenuTitle.COMPANIES,
      icon: <AccountBalanceOutlinedIcon />,
    },
  },
  {
    path: Path.TASKS,
    component: <Tasks />,
    title: PageTitle.TASKS,
    sideMenuOption: {
      title: SideMenuTitle.TASKS,
      icon: <HomeRepairServiceIcon />,
    },
    restrictedUserRoles: [Role.ADMIN],
  },
  {
    path: Path.PAYMENTS,
    component: <Payments />,
    sideMenuOption: {
      title: SideMenuTitle.PAYMENTS,
      icon: <LocalAtmOutlinedIcon />,
    },
    restrictedUserRoles: [Role.ADMIN],
  },
  {
    path: Path.CASH_FLOWS,
    component: <CashFlows />,
    title: PageTitle.CASH_FLOWS,
    sideMenuOption: {
      title: SideMenuTitle.CASH_FLOWS,
      icon: <ImportExportOutlinedIcon />,
    },
    restrictedUserRoles: [Role.ADMIN],
  },
  {
    path: Path.USERS,
    component: <Users />,
    title: PageTitle.USERS,
    sideMenuOption: {
      title: SideMenuTitle.USERS,
      icon: <PersonOutlineOutlinedIcon />,
    },
    restrictedUserRoles: [Role.ADMIN],
  },
];

export default pages;

import React from 'react';
import {
  IconButton, TableBody, TableCell, TableRow,
} from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { SortDirection } from '../../../../interfaces';
import { EmptyRows } from '../../../../shared/components';
import tableHelper from '../../../../shared/utils/table-helper';
import useTableContext from '../../../../context/table/context';
import Task from '../../../../models/task';
import tasksTableService from '../../service/tasks-table-service';

interface Props {
  tasks: Task[];
  sortDirection: SortDirection,
  orderByKey: keyof Task;
  page: number,
  onDisableTask: (taskId: number) => void;
}

export default function TasksTableBody({
  tasks, sortDirection, orderByKey, page, onDisableTask,
}: Props) {
  const { defaultRowsPerPage } = useTableContext();
  return (
    <TableBody>
      {tableHelper.getTablePageRecords(tasks, page, defaultRowsPerPage, sortDirection, orderByKey)
        .map((task, index) => {
          const labelId = `companies-table-${index}`;

          return (
            <TableRow
              hover
              tabIndex={-1}
              key={task.taskId}
            >
              <TableCell id={labelId} align="center">
                {task.taskId}
              </TableCell>
              <TableCell align="left">
                {tableHelper.getLimitedCellTextContent(task.name)}
              </TableCell>
              <TableCell align="center">
                <IconButton onClick={() => onDisableTask(task.taskId)}>
                  <RemoveCircleOutlineIcon color="primary" />
                </IconButton>
              </TableCell>
            </TableRow>
          );
        })}

      <EmptyRows
        page={page}
        recordsAmount={tasks.length}
        columnsAmount={tasksTableService.columnsAmount}
      />
    </TableBody>
  );
}

import React from 'react';
import {
  Paper, Table, TableContainer,
} from '@mui/material';
import { SortDirection } from '../../../../interfaces';
import { TableBodySkeleton, TableHeader, TablePagination } from '../../../../shared/components';
import Task from '../../../../models/task';
import tasksTableService from '../../service/tasks-table-service';
import TasksTableBody from '../tasks-table-body/tasks-table-body';

interface Props {
  tasks: Task[];
  isLoadingTasks: boolean;
  onDisableTask: (taskId: number) => void;
}

export default function TasksTable({ tasks, isLoadingTasks, onDisableTask }: Props) {
  const [sortDirection, setSortDirection] = React.useState<SortDirection>('asc');
  const [orderByKey, setOrderByKey] = React.useState<keyof Task>('name');
  const [page, setPage] = React.useState(0);

  return (
    <Paper>
      <TableContainer style={{ tableLayout: 'fixed' }}>
        <Table
          classes={{ root: 'max-w-full whitespace-nowrap' }}
          size="medium"
        >
          <TableHeader<Task>
            headerCells={tasksTableService.headerCells}
            sortDirection={sortDirection}
            orderByKey={orderByKey}
            setOrderByKey={setOrderByKey}
            setSortDirection={setSortDirection}
          />

          {!isLoadingTasks && (
            <TasksTableBody
              tasks={tasks}
              page={page}
              sortDirection={sortDirection}
              orderByKey={orderByKey}
              onDisableTask={onDisableTask}
            />
          )}

          {isLoadingTasks && (
            <TableBodySkeleton
              columnsAmount={tasksTableService.columnsAmount}
            />
          )}
        </Table>
      </TableContainer>
      <TablePagination
        recordsAmount={tasks.length}
        page={page}
        setPage={setPage}
      />
    </Paper>
  );
}

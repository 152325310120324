import { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { api } from '../../../libs';
import GetTasksResponse from '../../../libs/api/responses/task/get-tasks-response';
import Task from '../../../models/task';
import ErrorResponse from '../../../libs/api/responses/error-response';
import CreateTaskAttributes from '../../../interfaces/tasks/create-task-attributes';
import CreateTaskResponse from '../../../libs/api/responses/task/create-task-response';
import errorMessages from '../../../libs/api/helpers/error-messages';
import toastMessages from '../../../shared/utils/toast-messages';

const createTask = async (createTaskAttributes: CreateTaskAttributes): Promise<Task> => {
  let createTaskResponse: AxiosResponse<CreateTaskResponse>;
  try {
    createTaskResponse = await api.task.createTask(createTaskAttributes);
  } catch (error: unknown) {
    const errorMessage = (error as AxiosError<ErrorResponse>).response?.data.message;

    switch (errorMessage) {
      case errorMessages.TASK_NAME_ALREADY_EXISTS: {
        toast.error(toastMessages.error.TASK_NAME_ALREADY_EXISTS);
        break;
      }
      default: {
        toast.error(toastMessages.error.DEFAULT);
      }
    }

    throw (error as AxiosError<ErrorResponse>);
  }

  toast.success(toastMessages.success.DEFAULT);
  return createTaskResponse?.data;
};

const getTasks = async (): Promise<GetTasksResponse> => {
  const getTasksResponse: AxiosResponse<GetTasksResponse> = await api.task.getTasks();
  const sortedTasks = getTasksResponse.data.sort((a, b) => a.name.localeCompare(b.name));
  return sortedTasks;
};

const disableTask = async (taskId: number): Promise<void> => {
  try {
    await api.task.disableTask(taskId.toString());
  } catch (error: unknown) {
    toast.error(toastMessages.error.DEFAULT);
    throw (error as AxiosError<ErrorResponse>);
  }

  toast.success(toastMessages.success.DEFAULT);
};

const partialMatchKeys = ['name'] as (keyof Task)[];
const fullMatchKeys = ['taskId'] as (keyof Task)[];

const tasksService = {
  createTask,
  getTasks,
  disableTask,
  partialMatchKeys,
  fullMatchKeys,
};

export default tasksService;

import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal } from '../../../../shared/components';
import CreateTaskAttributes from '../../../../interfaces/tasks/create-task-attributes';

interface Props {
  createTask: (createTaskAttributes: CreateTaskAttributes) => void;
  isOpen: boolean;
  onClose: () => void;
}

export default function CreateTaskModal(
  { createTask, isOpen, onClose }: Props,
) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const {
    handleSubmit, register, reset, formState: { errors, isValid },
  } = useForm<CreateTaskAttributes>({
    mode: 'onTouched',
    defaultValues: {
      name: '',
    },
  });

  const handleCreateTaskSubmit = (createTaskAttributes: CreateTaskAttributes) => {
    setIsButtonLoading(true);

    createTask(createTaskAttributes);
    reset();

    setIsButtonLoading(false);
  };

  return (
    <Modal title="Nova Serviço" isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(handleCreateTaskSubmit)} className="flex flex-col">
        <TextField
          label="Nome"
          variant="standard"
          required
          error={!!errors.name}
          fullWidth
          classes={{ root: 'mb-10' }}
          {...register('name', { required: true, maxLength: 80 })}
        />

        <div>
          <LoadingButton
            variant="contained"
            type="submit"
            disabled={!isValid}
            loading={isButtonLoading}
          >
            Criar
          </LoadingButton>
        </div>
      </form>
    </Modal>
  );
}

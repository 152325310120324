import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Autocomplete,
  Checkbox,
  FormControl, FormControlLabel, FormGroup, FormHelperText, InputLabel, MenuItem, Select, TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import dayjs from 'dayjs';
import {
  DatePickerInput, Modal, TrialNumberTextField, AmountTextField,
} from '../../../../shared/components';
import { Role, TrialSide } from '../../../../shared/enums';
import { emailValidationRegex } from '../../../../shared/utils/regex';
import { CreateTrialAttributes } from '../../../../interfaces';
import getTrialSideLabel from '../../../../shared/utils/get-trial-side-label';
import companiesService from '../../../companies/services/companies-service';
import usersService from '../../../users/services/users-service';
import Company from '../../../../models/company';
import User from '../../../../models/user';
import formHelper from '../../../../shared/utils/form-helper';
import dateHelper from '../../../../shared/utils/date-helper';
import { TRIAL_NUMBER_LENGTH } from '../../../../shared/utils/constants';
import isValidTrialNumber from '../../../../shared/utils/trial/is-valid-trial-number';
import useUserContext from '../../../../context/user/context';
import Task from '../../../../models/task';
import tasksService from '../../../tasks/service/tasks-service';
import trialsService from '../../services/trials-service';

interface Props {
  createTrial: (createTrialAttributes: CreateTrialAttributes) => Promise<boolean>;
  isOpen: boolean;
  onClose: () => void;
}

export default function CreateTrialModal(
  { createTrial, isOpen, onClose }: Props,
) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isCompaniesFetching, setIsCompaniesFetching] = useState(true);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [isUsersFetching, setIsUsersFetching] = useState(true);
  const [users, setUsers] = useState<User[]>([]);
  const [isTasksFetching, setIsTasksFetching] = useState(true);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [checkedTrialRequestedByOffice, setCheckedTrialRequestedByOffice] = useState<boolean>(false);
  const [selectedClient, setSelectedClient] = useState<Company | null>(null);
  const [selectedOffice, setSelectedOffice] = useState<Company | null>(null);
  const [foundTrialNumber, setFoundTrialNumber] = useState<boolean>(false);
  const { user } = useUserContext();

  const {
    handleSubmit, register, reset, setValue, control, formState: { errors, isValid },
  } = useForm<CreateTrialAttributes>({
    mode: 'onTouched',
    defaultValues: {
      deadline: dateHelper.getStandardizedDate(dayjs()),
      trialNumber: null,
      side: null,
      amount: 0,
    },
  });

  const fetchCompaniesData = async () => {
    setIsCompaniesFetching(true);
    const fetchedCompanies = await companiesService.getCompanies().catch(() => []);
    setCompanies(fetchedCompanies);
    setIsCompaniesFetching(false);
  };

  const fetchTasksData = async () => {
    setIsTasksFetching(true);
    const fetchedTasks = await tasksService.getTasks().catch(() => []);
    setTasks(fetchedTasks);
    setIsTasksFetching(false);
  };

  const fetchUsersData = async () => {
    const fetchedUsers = await usersService.getUsers().catch(() => []);
    setUsers(fetchedUsers);
    setIsUsersFetching(false);
  };

  const fetchTrialDataByTrialNumber = async (trialNumber: string) => {
    trialsService.getTrial(trialNumber)
      .then((trial) => {
        setValue('trialNumber', trial.trialNumber);
        setValue('clientId', trial.clientId);
        setValue('officeId', trial.officeId);
        setValue('trialRequestedByOffice', trial.trialRequestedByOffice);
        if (trial.trialRequestedByOffice) setCheckedTrialRequestedByOffice(true);
        setValue('lawyer', trial.lawyer);
        setValue('email', trial.email);
        setValue('side', trial.side);
        setValue('plaintiff', trial.plaintiff);
        setValue('defendant', trial.defendant);
        setValue('amount', trial.amount);

        const clientCompany = companies.find((company) => company.companyId === trial.clientId);
        const officeCompany = companies.find((company) => company.companyId === trial.officeId);

        setSelectedClient(clientCompany || null);
        setSelectedOffice(officeCompany || null);

        setFoundTrialNumber(true);
      })
      .catch(() => {
        setFoundTrialNumber(false);
      });
  };

  const handleCreateTrialSubmit = async (createTrialAttributes: CreateTrialAttributes) => {
    setIsButtonLoading(true);

    const createTrialBody = formHelper.setUndefinedStringsToFalse<CreateTrialAttributes>(formHelper.setEmptyStringsToNull<CreateTrialAttributes>(createTrialAttributes));
    const isSuccess = await createTrial({
      ...createTrialBody,
      side: createTrialBody.side ? createTrialBody.side : null,
    });

    if (isSuccess) {
      reset();
      setSelectedClient(null);
      setSelectedOffice(null);
      setCheckedTrialRequestedByOffice(false);
    }

    setIsButtonLoading(false);
  };

  useEffect(() => {
    fetchCompaniesData();
    fetchTasksData();
    fetchUsersData();
  }, []);

  return (
    <Modal title="Novo Processo" isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(handleCreateTrialSubmit)} className="flex flex-col">
        <h2 className="font-bold mb-5 ">Número do contrato</h2>
        <Controller
          control={control}
          name="trialNumber"
          rules={{ validate: (trialNumber) => (!trialNumber || isValidTrialNumber(trialNumber)) || `O numero deve possuir ${TRIAL_NUMBER_LENGTH} dígitos` }}
          render={({
            field: {
              onChange, onBlur, value, ref,
            },
          }) => (
            <TrialNumberTextField
              label="Identificador do Processo"
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="standard"
              classes={{ root: 'mb-15' }}
              onChange={(trialNumber: string | null) => {
                onChange(trialNumber);
                if (trialNumber && trialNumber.length === 20) {
                  fetchTrialDataByTrialNumber(trialNumber);
                } else {
                  setFoundTrialNumber(false);
                }
              }}
              onBlur={onBlur}
              value={value}
              error={!!errors.trialNumber}
              helperText={errors.trialNumber?.message}
              ref={ref}
              sx={{
                '& .MuiInput-underline:before': {
                  borderBottomColor: foundTrialNumber ? '#4caf50' : undefined,
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: foundTrialNumber ? '#4caf50' : undefined,
                },
              }}
            />
          )}
        />
        <h2 className="font-bold mb-5 ">Dados de clientes</h2>
        <FormControl variant="standard" classes={{ root: 'w-full mb-5' }} fullWidth>
          <Autocomplete
            id="responsible-select"
            options={users}
            getOptionLabel={(option: User) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                id="role-responsible-select"
                label="Responsável"
                variant="standard"
                disabled={isUsersFetching}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            )}
            onChange={(_, value) => {
              if (value) {
                setValue('responsibleId', value.userId);
              } else {
                setValue('responsibleId', null);
              }
            }}
          />
        </FormControl>
        <FormControl variant="standard" classes={{ root: 'w-full mb-5' }} fullWidth>
          <Autocomplete
            id="client-select"
            options={companies}
            value={selectedClient}
            getOptionLabel={(option: Company) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                id="role-client-select"
                label="Cliente"
                variant="standard"
                disabled={isCompaniesFetching}
                InputLabelProps={{ shrink: true }}
                fullWidth
                required
              />
            )}
            onChange={(_, value) => {
              setSelectedClient(value);
              if (value) {
                setValue('clientId', value.companyId);
              }
            }}
          />
        </FormControl>

        <FormControl variant="standard" classes={{ root: 'w-full' }} fullWidth>
          <Autocomplete
            id="office-select"
            options={companies}
            value={selectedOffice}
            getOptionLabel={(option: Company) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                id="role-office-select"
                label="Escritório"
                variant="standard"
                disabled={isCompaniesFetching}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            )}
            onChange={(_, value) => {
              if (value) {
                setValue('officeId', value.companyId);
                setSelectedOffice(value);
                setCheckedTrialRequestedByOffice(false);
                setValue('trialRequestedByOffice', false);
              } else {
                setValue('officeId', null);
                setSelectedOffice(null);
                setCheckedTrialRequestedByOffice(false);
                setValue('trialRequestedByOffice', false);
              }
            }}
          />
          <FormHelperText>Para não adicionar um escritório, basta não selecionar nada neste campo</FormHelperText>
        </FormControl>

        <FormGroup>
          <FormControlLabel
            control={(
              <Checkbox
                checked={checkedTrialRequestedByOffice}
                onChange={() => setCheckedTrialRequestedByOffice(!checkedTrialRequestedByOffice)}
              />
            )}
            label="Este processo foi solicitado pelo escritório de advocacia"
            disabled={!selectedOffice}
            value={checkedTrialRequestedByOffice}
            style={{ color: '#4f5b62', marginBottom: '10px' }}
            {...register('trialRequestedByOffice')}
          />
        </FormGroup>

        <TextField
          label="Advogado"
          variant="standard"
          fullWidth
          classes={{ root: 'mb-5' }}
          InputLabelProps={{ shrink: true }}
          {...register('lawyer', { maxLength: 80 })}
        />

        <TextField
          label="Email de contato"
          variant="standard"
          fullWidth
          type="text"
          classes={{ root: 'mb-15' }}
          error={!!errors.email}
          helperText={errors.email?.message}
          InputLabelProps={{ shrink: true }}
          {...register('email', {
            maxLength: 80,
            pattern: { value: emailValidationRegex, message: 'Campo de email inválido' },
          })}
        />

        <h2 className="font-bold mb-5 ">Dados de processo</h2>

        <FormControl variant="standard" classes={{ root: 'w-full mb-5' }} fullWidth>
          <Autocomplete
            id="task-select"
            options={tasks}
            getOptionLabel={(option: Task) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                id="role-client-select"
                label="Tipo de Processo"
                variant="standard"
                disabled={isTasksFetching}
                InputLabelProps={{ shrink: true }}
                fullWidth
                required
              />
            )}
            onChange={(_, value) => {
              setValue('taskId', value?.taskId!);
            }}
          />
        </FormControl>

        <div className="flex flex-row">
          <div className="w-full">
            <Controller
              control={control}
              name="deadline"
              render={({
                field: {
                  onChange,
                  value,
                },
              }) => (
                <div className="mr-9">
                  <DatePickerInput onChange={onChange} value={value} label="Prazo" />
                </div>
              )}
            />
          </div>
          <div className="w-full">
            <FormControl variant="standard" classes={{ root: 'w-full mb-5' }} fullWidth>
              <InputLabel id="role-select-trial-side" shrink>Parte</InputLabel>
              <Controller
                control={control}
                name="side"
                render={({
                  field: { onChange, value },
                }) => (
                  <Select
                    labelId="role-select-trial-side"
                    id="role-select"
                    fullWidth
                    value={value || ''}
                    onChange={onChange}
                  >
                    <MenuItem key="null" value=""><em>Nenhum</em></MenuItem>
                    {Object.values(TrialSide).map((sideValue) => (
                      <MenuItem key={sideValue} value={sideValue}>
                        {getTrialSideLabel(sideValue)}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </div>
        </div>

        <TextField
          label="Autor"
          variant="standard"
          fullWidth
          classes={{ root: 'mb-5' }}
          InputLabelProps={{ shrink: true }}
          {...register('plaintiff', { maxLength: 80 })}
        />

        <TextField
          label="Réu"
          variant="standard"
          fullWidth
          classes={{ root: 'mb-15' }}
          InputLabelProps={{ shrink: true }}
          {...register('defendant', { maxLength: 80 })}
        />

        <h2 className="font-bold mb-5 ">Observações</h2>
        <TextField
          label="Anotações Privadas"
          variant="standard"
          fullWidth
          multiline
          rows={4}
          classes={{ root: 'mb-5' }}
          {...register('privateAnnotations', { maxLength: 600 })}
        />

        <FormControl variant="standard" classes={{ root: 'w-full mb-10' }} fullWidth>

          <TextField
            label="Anotações Públicas"
            variant="standard"
            fullWidth
            multiline
            rows={4}
            classes={{ root: 'mb-5' }}
            {...register('publicAnnotations', { maxLength: 600 })}
          />
          <FormHelperText>O texto acima ficará disponível em relatórios e recibos enviados para o cliente</FormHelperText>
        </FormControl>

        {user?.role === Role.ADMIN && (
          <>
            <Controller
              control={control}
              name="amount"
              render={({ field: { onChange, value } }) => (
                <AmountTextField
                  label="Valor"
                  fullWidth
                  variant="standard"
                  onChange={onChange}
                  value={value}
                />
              )}
            />

            <br />
          </>
        )}

        <div>
          <LoadingButton
            variant="contained"
            type="submit"
            disabled={!isValid}
            loading={isButtonLoading}
          >
            Criar
          </LoadingButton>
        </div>
      </form>
    </Modal>
  );
}

import axiosInstance from './config/axios';
import GetTasksResponse from './responses/task/get-tasks-response';
import CreateTaskRequest from './requests/task/create-task-request';
import CreateTaskResponse from './responses/task/create-task-response';

const TASK_BASE_URL = '/task';

const createTask = async (request: CreateTaskRequest) => (axiosInstance.post<CreateTaskResponse>(TASK_BASE_URL, request));

const getTasks = async () => (axiosInstance.get<GetTasksResponse>(TASK_BASE_URL));

const disableTask = async (taskId: string) => (axiosInstance.put(`${TASK_BASE_URL}/${taskId}/disable`));

export default {
  createTask,
  getTasks,
  disableTask,
};
